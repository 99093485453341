











































import { Observer } from "mobx-vue";
import { Component, Vue, Provide, Watch, Inject } from "vue-property-decorator";
import { TrainingGroundEventViewmodel } from "../viewmodels/training-ground-event-viewmodel";

@Observer
@Component({})
export default class MonsterDialog extends Vue {
  @Inject() vmEvent!: TrainingGroundEventViewmodel;
}
